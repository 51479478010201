import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TripoProvider from "./provider/TripoProvider";

import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import "./index.css";
// import "@fontsource/inter/300.css";
// import "@fontsource/inter/400.css";
// import "@fontsource/inter/500.css";
// import "@fontsource/inter/700.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <TripoProvider>
    <App />
  </TripoProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
