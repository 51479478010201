export const SNOWFLAKE_REDIRECT_URL =
  process.env.REACT_APP_SNOWFLAKE_REDIRECT_URL;
export const SNOWFLAKE_TEST_CLIENT_ID =
  process.env.REACT_APP_SNOWFLAKE_TEST_CLIENT_ID;
export const SNOWFLAKE_TEST_CLIENT_SECRET =
  process.env.REACT_APP_SNOWFLAKE_TEST_CLIENT_SECRET;
export const SNOWFLAKE_TEST_TOKEN_ENDPOINT =
  process.env.REACT_APP_SNOWFLAKE_TEST_TOKEN_ENDPOINT;
export const SNOWFLAKE_TEST_ACCOUNT_IDENTIFIER =
  process.env.REACT_APP_SNOWFLAKE_TEST_ACCOUNT_IDENTIFIER;
export const SNOWFLAKE_TEST_WAREHOUSE =
  process.env.REACT_APP_SNOWFLAKE_TEST_WAREHOUSE;
