export const tenantId = "95c228f4-944c-49ba-abcd-a00985e5497c";
export const redirectUri = process.env.REACT_APP_MSAL_REDIRECT_URI;
export const clientId = process.env.REACT_APP_MSAL_CLIENT_ID;
export const authority = `https://login.microsoftonline.com/${tenantId}`;

// Uncomment localhost when development, and comment for deployment
export const msalConfig = {
  auth: {
    clientId,
    authority,
    redirectUri
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
};

export const loginRequest = {
  scopes: ["api://aeec21ea-2bcc-43b1-afb0-4aaad8f8de26/api.access"]
};
